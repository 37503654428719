import Home from './Components/Home'
import NavBar from './Components/NavBar'
import Footer from './Components/Footer'
import Sea from './Components/Sea'
import Air from './Components/Air'

// import Calendly from './Components/Calendly'
// import ScrollToTop from './Components/ScrollToTop'

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from './Components/Contact';
import About from './Components/About';




function App() {
  return (
   <div>

      <BrowserRouter>
        {/* <ScrollToTop></ScrollToTop> */}

        <NavBar></NavBar>
        <Routes>

          <Route path="/" element={<Home></Home>} />
          <Route path="/sea-cargo" element={<Sea></Sea>} />
          <Route path="/air-cargo" element={<Air></Air>} />
          <Route path="/about" element={<About></About>} />

          <Route path="/contact" element={<Contact></Contact>} />





        </Routes>
        <Footer></Footer>
      </BrowserRouter>

   </div>
  );
}

export default App;
