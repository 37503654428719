import React from "react";
import "./Sea.css";
import bannerstarsimg from "../Images/Group 1000008511.svg";
import bannerlisvg from "../Images/Group 1000008655.svg";
import seaboatimg from "../Images/image 97.jpg";
import Section from "./Section";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";


function Air() {
  return (
    <div>
      <Helmet>
        <title>PK Cargo </title>
        <meta
          name="description"
          content="Comprehensive Cargo Solutions: Explore Our Range of Services Tailored for Your Shipping Needs. From Express Deliveries to Specialized Handling, We've Got You Covered. Experience Seamless Transfers from the UK to Pakistan with Our Dedicated Services."
        />
      </Helmet>
      <div className="container-fluid air-banner-div1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 for-mobile-about-banner-col">
              <div>

                <div className="home-banner-stars-div1">
                  <img src={bannerstarsimg} />
                  <p>Loved by 100,000+ Happy cusmtomers</p>
                </div>
                <div className="d-md-block d-none">
                  <Fade bottom distance="1%">
                    <h1 className="home-banner-text1">
                      Send Cargo
                      <span> By Air</span>
                    </h1>
                  </Fade>
                  <Fade bottom distance="1%">
                    <p className="sea-banner-para1">
                      {" "}
                      Send your cargo by air for fast, reliable delivery
                      worldwide. Trust us to handle your shipments securely and
                      efficiently, ensuring they reach their destination on
                      time, every time.{" "}
                    </p>
                  </Fade>
                  <Fade bottom distance="1%">
                    <ul className="home-banner-ul-div1">
                      <li>
                        <img src={bannerlisvg} alt="" />
                        <p>Hassle Free </p>
                        <img src={bannerlisvg} className="ml-4" alt="" />
                        <p>On Time</p>
                      </li>

                      <li>
                        <img src={bannerlisvg} alt="" />
                        <p>Door to Door Service</p>
                      </li>
                    </ul>
                  </Fade>
                  <div>
                    <Fade bottom distance="1%">
                      <HashLink
                        to="#contact-form"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={600}
                      >
                        <div className="banner-quote-btn1">
                          Get a Quote{" "}
                          <svg
                            width="15"
                            height="13"
                            viewBox="0 0 15 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.80958 13L7.77052 11.9744L12.0482 7.69673H0.969379V6.21236H12.0482L7.77052 1.94815L8.80958 0.909091L14.855 6.95455L8.80958 13Z"
                              fill="#01070C"
                            />
                          </svg>
                        </div>
                      </HashLink>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>

            <Fade bottom distance="1%">
              <div className="col-lg-6 sea-banner-card-maindiv1">
                <div>
                  <div className="sea-banner-card-div1">
                    <div className="sea-banner-card-svgdiv1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="88"
                        height="77"
                        viewBox="0 0 88 77"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_407_2798)">
                          <path
                            d="M20.3438 64.0859H61.688"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M39.9727 46.8415L57.8637 36.0703"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.5703 43.8594L18.5036 50.7927"
                            stroke="#F7B53C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.54688 41.3516L8.48016 48.2848"
                            stroke="#F7B53C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.5711 41.042L29.4721 51.4987C30.9257 52.8947 32.7801 53.7726 34.7598 54.0021C36.7395 54.2316 38.7386 53.8004 40.4598 52.7725L84.2373 26.7046L78.859 19.9507C77.4601 18.2002 75.496 17.02 73.3218 16.6234C71.1476 16.2267 68.9067 16.64 67.0039 17.7881L54.1656 25.5197L36.8165 19.5952L32.0456 21.6984C31.6886 21.855 31.3759 22.1013 31.1364 22.4144C30.8967 22.7274 30.7379 23.0973 30.6745 23.4898C30.6111 23.8823 30.6452 24.2849 30.7736 24.6603C30.9021 25.0358 31.1207 25.372 31.4094 25.6382L40.2864 33.814L32.1901 38.5536L24.0939 34.9989L19.2362 37.1317C18.8828 37.2873 18.5727 37.5306 18.3343 37.8398C18.0958 38.149 17.9363 38.5142 17.8702 38.9024C17.8043 39.2906 17.8338 39.6895 17.9561 40.0631C18.0785 40.4367 18.2899 40.7731 18.5711 41.042Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M79.8553 21.4827C72.9049 26.9415 66.044 27.6277 61.0625 21.4827L64.9767 18.9384C69.8408 15.7767 76.3181 16.8843 79.8553 21.4827Z"
                            fill="#F7B53C"
                            stroke="black"
                            stroke-width="2"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_407_2798">
                            <rect
                              width="87.5996"
                              height="76.0733"
                              fill="white"
                              transform="translate(0.398438 0.1875)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <h4 className="d-md-none">Send Air Cargo</h4>
                    </div>
                    <h2>
                      <div></div> FROM £8.99
                      <span className="d-md-block d-none ml-2">[ Per Kg ]</span>
                      <span className="d-md-none d-block">/KG</span>
                    </h2>
                    <ul>
                      <li>
                        Minimum<span> 20 Kg</span>
                      </li>
                      <li>
                        Over 50 kg<span> £8.50 Per Kg</span>
                      </li>
                    </ul>

                    <div>
                      <HashLink
                        to="#contact-form"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={600}
                      >
                        {" "}
                        <div className="sea-btn1">Get a Quote</div>
                      </HashLink>
                    </div>
                  </div>
                </div>

              </div>

            </Fade>
          </div>
        </div>
      </div>

      <div className="container-fluid sea-services-div12">
        <div className="container">
          <Fade bottom distance="1%">
            <h2 className="sea-services-text1">
              Air <span>cargo </span>
            </h2>
          </Fade>
          <Fade bottom distance="1%">
            <p className="sea-services-text2">
              "Globalization has transformed the business landscape, allowing
              companies to expand their reach beyond borders. Trusted cargo
              services, such as air bridge cargo, play a vital role in
              facilitating this global trade, enabling businesses to operate on
              an international scale. Secure shipment is paramount for
              companies, ensuring the safe transport of goods across
              continents."
            </p>
          </Fade>
          <div className="sea-services-text-div1">
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Speedy delivery
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Reduced transit times
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Secure handling of goods
              </p>
            </Fade>
          </div>
          <div className="sea-services-text-div1">
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Global reach
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Ideal for urgent shipments
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Minimal risk of damage or loss
              </p>
            </Fade>
          </div>
          <Fade bottom distance="1%">
            <img src={seaboatimg} className="sea-boat-img12 img-fluid" alt="" />
          </Fade>
          <Fade bottom distance="1%">
            <p className="sea-boat-para1">
              Cargo services, like air bridge cargo, have revolutionised global
              accessibility. From clothes to machinery, they enable individuals
              to receive items from any country. With 24/7 availability and
              tracking, customers stay informed throughout the shipment process.
            </p>
          </Fade>
        </div>
      </div>

      <Section></Section>
    </div>
  );
}

export default Air;
