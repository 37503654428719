import React from "react";
import bannerstarsimg from "../Images/Group 1000008511.svg";
import "./About.css";
import aboutbannerimg1 from "../Images/boat 1.png";
import aboutmappic from "../Images/Group 1000008662.jpg";
import Section from "./Section";
import cardimg1 from "../Images/Group 1000008659.svg";
import cardimg2 from "../Images/Group 1000008660.svg";
import cardimg3 from "../Images/Group 1000008517.svg";
import cardimg4 from "../Images/Group 1000008658.svg";
import abouttruckimg from "../Images/Trailer-Truck-Mockup-Free-PSD---View-1 1.jpg";
import loadimg from "../Images/Isometric-transportation-vector-14-Converted 1.png";
import doorimg from "../Images/door-door 1.png";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";


function About() {
  return (
    <div>
      <Helmet>
        <title>PK Cargo </title>
        <meta name="description" content="Discover Our Story: Premier Cargo Logistics Bridging the Gap 
        Between UK and Pakistan. Learn About Our Commitment to Excellence, Customer Satisfaction, 
        and Global Connectivity in Freight Transport Solutions." />
      </Helmet>
      <div className="conatiner-fluid about-banner-div1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 for-mobile-about-banner-col">
              <div>
                {/* <Fade bottom distance="1%"> */}
                <div className="home-banner-stars-div1">
                  <img src={bannerstarsimg} />
                  <p>Loved by 100,000+ Happy cusmtomers</p>
                </div>
                {/* </Fade> */}
                <div>
                  {/* <Fade bottom distance="1%"> */}
                  <h1 className="home-banner-text1">
                    Your Reliable Logistics Partner
                    <span> Cargo to Pakistan and Kashmir</span>
                  </h1>
                  {/* </Fade> */}
                  {/* <Fade bottom distance="1%"> */}
                  <p className="about-para-1">
                    PK Cargo offers fast-track global shipping excellence. Our
                    seamless logistics ensure efficient delivery by air, sea,
                    or land. With a focus on reliability and security, we're
                    your trusted partner in worldwide logistics, connecting
                    you to markets with ease."
                  </p>
                  {/* </Fade> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                {/* <Fade bottom distance="1%"> */}
                <img
                  src={aboutbannerimg1}
                  className="about-banner-img23 "
                  alt=""
                />
                {/* </Fade> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <img src={aboutmappic} className="img-fluid about-map-pic" />

          <Fade bottom distance="1%">
            <h2 className="about-map-text1">UK TO PAKISTAN</h2>
          </Fade>
        </div>
      </div>

      {/* // ########### Section No 3 ############ */}
      <div className="container-fluid home-sec3-card-div11">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <img src={cardimg1} alt="" />
                  </div>
                  <h2>50+</h2>
                  <p>Countries Served</p>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <img src={cardimg2} alt="" />
                  </div>
                  <h2>100K</h2>
                  <p>Delivery Made</p>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <img src={cardimg3} alt="" />
                  </div>
                  <h2>99%</h2>
                  <p>Satisfaction Rate</p>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <img src={cardimg4} alt="" />
                  </div>
                  <h2>24/7</h2>
                  <p>Support Hours</p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid about-truck-img12">
        <div className="container">
          {/* <Fade bottom distance="1%"> */}
          <img src={abouttruckimg} className="img-fluid truck-img-animation" alt="" />
          {/* </Fade> */}
        </div>
      </div>

      <div className="container-fluid about-last-sec-div1 d-md-none d-block">
        <div className="container">
          <div className="text-center">
            <Fade bottom distance="1%">
              <h2>
                Door to door <span>Services</span>{" "}
              </h2>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                Our cargo transportation procedure is streamlined for simplicity
                and efficiency, ensuring a seamless experience for all
                customers. To begin, visit our website and specify the origin of
                your goods. Then, select your preferred mode of
                transport—whether air, sea, or a combination. Our system will
                calculate charges based on weight, providing transparent
                pricing. Input your contact details and click 'Get Shipping
                Prices' to submit. We'll promptly process your order,
                transporting your goods with care.
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <img src={doorimg} className="img-fluid" />
            </Fade>
          </div>
        </div>
      </div>

      <div className="movers-maindiv1">
        <div className="container-fluid movers-div1">
          <div className="row">
            <div className="col-lg-6 col-12 movers-img-div1">
              <div>
                <Fade bottom distance="1%">
                  <img src={loadimg} alt="" />
                </Fade>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center usa-movers-div233">
              <div className="text-center">
                <Fade bottom distance="1%">
                  <h1 className="movers-text1">
                    Our Procedure <br /> <span>for transporting goods!</span>
                  </h1>
                </Fade>
                <Fade bottom distance="1%">
                  <p className="movers-para-12">
                    Our cargo transportation procedure is streamlined for
                    simplicity and efficiency, ensuring a seamless experience
                    for all customers. To begin, visit our website and specify
                    the origin of your goods. Then, select your preferred mode
                    of transport—whether air, sea, or a combination. Our system
                    will calculate charges based on weight, providing
                    transparent pricing. Input your contact details and click
                    'Get Shipping Prices' to submit. We'll promptly process your
                    order, transporting your goods with care.
                  </p>
                </Fade>

                <div className="d-md-none d-block">
                  <Fade bottom distance="1%">
                    <img src={loadimg} className=" img-fluid" alt="" />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid about-last-sec-div1 d-md-block d-none">
        <div className="container">
          <div>
            <Fade bottom distance="1%">
              <p>
                We're committed to delivering your items swiftly and securely.
                Your satisfaction is our priority, and we're dedicated to
                providing exceptional service every step of the way. Experience
                hassle-free cargo transportation with us.
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <h2>
                Door to door <span>Services</span>{" "}
              </h2>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                Our cargo transportation procedure is streamlined for simplicity
                and efficiency, ensuring a seamless experience for all
                customers. To begin, visit our website and specify the origin of
                your goods. Then, select your preferred mode of
                transport—whether air, sea, or a combination. Our system will
                calculate charges based on weight, providing transparent
                pricing. Input your contact details and click 'Get Shipping
                Prices' to submit. We'll promptly process your order,
                transporting your goods with care.
              </p>
            </Fade>
          </div>
        </div>
      </div>

      <Section></Section>
    </div>
  );
}

export default About;
