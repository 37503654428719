import React, { useState } from "react";
import "./Navbar.css";
import navlogo from "../Images/Screenshot_2024-01-29_at_9 1.svg";
import { Link } from "react-router-dom";

import mobileboat from '../Images/boat 4.png' 
import mobileair from '../Images/plane 2.png'
import deskplane from '../Images/plane 2 (1).png'
import deskboat from '../Images/Group 1000008663.png'


function NavBar() {
  const [toggle, settoggle] = useState(true);

  return (
    <div>
      <div>
        <nav class="navbar navbar-expand-lg navbar-light navbar-div1 d-md-flex d-none">
          <div className="container">
            <Link class="navbar-brand" to="/">
              <img src={navlogo} className="navbar-logo1" alt="" />
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse mx-auto navbar-ul-div1"
              id="navbarNav"
            >
              <ul class="navbar-nav navbar-ul1 ">

                <li class="nav-item">
                  <Link class="nav-link" to="/">
                    Home
                  </Link>
                </li>

                <li class="nav-item">
                  <p>|</p>
                </li>

                <li class="nav-item">
                  <Link class="nav-link" to="/about">
                    About Us
                  </Link>
                </li>

                <li class="nav-item">
                  <p>|</p>
                </li>

                <li class="nav-item">
                  <div class="dropdown ">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                      Services
                    </a>
                    <div class="dropdown-menu ">
                      <div className='dropdown-menu-div12'>

                        <Link
                          class=""
                          to="/air-cargo"
                          onClick={() => {
                            settoggle(!toggle);
                          }}
                        >
                          <div className='nav-desk-card'>
                            <div className='nav-desk-top-text-div'>
                              <div className='nav-desk-top-img-div'>
                                <img src={deskplane} className="" />
                              </div>
                              <div>
                                <p className="nav-cargo-text12">Air Cargo</p>
                                <h2>Starting from</h2>
                                <h3>£ 8.99 <span>/KG</span></h3>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <Link
                          class=""
                          to="/sea-cargo"
                          onClick={() => {
                            settoggle(!toggle);
                          }}
                        >
                          <div className='nav-desk-card'>
                            <div className='nav-desk-top-text-div'>
                              <div className='nav-desk-top-img-div'>
                                <img src={deskboat} className="" />
                              </div>
                              <div>
                                <p className="nav-cargo-text12">Sea Cargo</p>
                                <h2>Starting from</h2>
                                <h3>£ 1.25 <span>/KG</span></h3>
                              </div>
                            </div>
                          </div>
                        </Link>

                      </div>
                    </div>
                  </div>
                </li>

                <li class="nav-item">
                  <p>|</p>
                </li>

                <li class="nav-item">
                  <Link class="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>

              </ul>
            </div>
            <div>
              <a href="tel:+447510074850" >
                <div className="navbar-contact-btn1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.54212 8.6421C9.04272 12.7357 10.5962 14.3691 14.5483 16.1087L12.8356 18.5141C8.29735 16.3414 6.03694 12.6789 4.97235 9.82076L7.54212 8.6421Z"
                      fill="black"
                    />
                    <path
                      d="M8.79918 2.98981C9.60159 2.98667 10.2117 3.65435 10.157 4.4104C10.0709 5.60016 10.1626 6.45855 10.4093 7.52256C10.5449 8.10703 10.2382 8.70064 9.75092 9.08298C9.0941 9.59833 8.59231 10.255 8.19349 10.8202C7.64071 11.6037 5.7384 11.5474 5.3177 10.6939C4.4531 8.93976 3.86716 5.70801 5.12973 3.52457C5.32804 3.18161 5.72736 3.00182 6.14113 3.0002L8.79918 2.98981Z"
                      fill="black"
                    />
                    <path
                      d="M20.981 15.972C21.0808 15.2201 20.4523 14.5678 19.651 14.5282C18.39 14.4658 17.4987 14.2766 16.4099 13.9173C15.8118 13.72 15.1509 13.9361 14.6904 14.347C14.0697 14.9008 13.3191 15.2923 12.6769 15.5982C11.7869 16.0222 11.6173 17.8125 12.4639 18.3096C14.2039 19.3312 17.5305 20.2692 19.9776 19.348C20.3619 19.2033 20.5989 18.8506 20.6504 18.4628L20.981 15.972Z"
                      fill="black"
                    />
                  </svg>{" "}
                  +44 7510074850
                </div>
              </a>
            </div>
            '
          </div>
        </nav>
      </div>







































      {/* ####### // mobile Nav Bar ###############*/}

      <nav class="navbar mobile-navbar-div1 d-flex d-lg-none navbar-expand-lg navbar-light ">
        <button
          class="navbar-toggler mobile-nav-toogler"
          type="button"
          // data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => settoggle(!toggle)}
        >
          <svg width="46" height="46" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" fill="url(#paint0_linear_741_91)" />
            <line x1="11" y1="15" x2="31" y2="15" stroke="black" stroke-width="2" />
            <line x1="11" y1="21" x2="31" y2="21" stroke="black" stroke-width="2" />
            <line x1="11" y1="27" x2="31" y2="27" stroke="black" stroke-width="2" />
            <defs>
              <linearGradient id="paint0_linear_741_91" x1="1.26736e-06" y1="5.03998" x2="40.8325" y2="8.65357" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7B53C" />
                <stop offset="0.463692" stop-color="#F7B53C" />
                <stop offset="1" stop-color="#FFCD73" />
              </linearGradient>
            </defs>
          </svg>
        </button>

        <Link class="navbar-brand text-center ml-0 mx-auto" 
        onClick={() => {settoggle(true); }} to="/">
          <img src={navlogo} alt="" className="" width="70" />
        </Link>

        <a href="tel:+447510074850" class="">
          <svg width="46" height="46" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" fill="url(#paint0_linear_743_91)" />
            <path d="M16.5421 18.6421C18.0427 22.7357 19.5962 24.3691 23.5483 26.1087L21.8356 28.5141C17.2973 26.3414 15.0369 22.6789 13.9724 19.8208L16.5421 18.6421Z" fill="black" />
            <path d="M17.8011 12.9898C18.6035 12.9867 19.2137 13.6544 19.159 14.4104C19.0729 15.6002 19.1645 16.4586 19.4113 17.5226C19.5468 18.107 19.2402 18.7006 18.7529 19.083C18.0961 19.5983 17.5943 20.255 17.1954 20.8202C16.6427 21.6037 14.7403 21.5474 14.3196 20.6939C13.455 18.9398 12.8691 15.708 14.1317 13.5246C14.33 13.1816 14.7293 13.0018 15.1431 13.0002L17.8011 12.9898Z" fill="black" />
            <path d="M29.981 25.9681C30.0808 25.2162 29.4523 24.5639 28.651 24.5243C27.39 24.4619 26.4987 24.2727 25.4099 23.9134C24.8118 23.716 24.1509 23.9322 23.6904 24.3431C23.0697 24.8969 22.3191 25.2884 21.6769 25.5943C20.7869 26.0183 20.6173 27.8086 21.4639 28.3057C23.2039 29.3273 26.5305 30.2653 28.9776 29.3441C29.3619 29.1994 29.5989 28.8467 29.6504 28.4589L29.981 25.9681Z" fill="black" />
            <defs>
              <linearGradient id="paint0_linear_743_91" x1="1.26736e-06" y1="5.03998" x2="40.8325" y2="8.65357" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7B53C" />
                <stop offset="0.463692" stop-color="#F7B53C" />
                <stop offset="1" stop-color="#FFCD73" />
              </linearGradient>
            </defs>
          </svg>
        </a>

        <div
          class={`${toggle
            ? "navbar-collapse mobile-navbar-div2"
            : "navbar-collapse mobile-navbar-div2 show"
            } `}
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mx-auto">
            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                Home
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/about"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                About Us
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>


            <li className="nav-item mobile-nav-item home1">
              <Link
                class=""
                to="/sea-cargo"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                <div className=''>
                  <div className='nav-mobile-top-text-div'>
                    <div>
                      <h2>Starting from</h2>
                      <h3>1.25 <span>/KG</span></h3>
                    </div>
                    <div className='home-mobile-top-img-div'>
                      <img src={mobileboat} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </Link>
            </li>


            <li className="nav-item mobile-nav-item home1">
              <Link
                class=""
                to="/air-cargo"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                <div className='nav-card'>
                  <div className='nav-mobile-top-text-div'>
                    <div>
                      <h2>Starting from</h2>
                      <h3>£ 8.99<span>/KG</span></h3>
                    </div>
                    <div className='home-mobile-top-img-div'>
                      <img src={mobileair} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </Link>
            </li>


            <li className="nav-item mobile-nav-item home1">
              <Link
                class=""
                to="/contact"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                <div className='mobile-qoute-btn122'>
                  {'Get Quote ->'}
                </div>
              </Link>
            </li>


            <li className="nav-item mobile-nav-item home1">
              <a
                class=""
                href="tel:+447510074850"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                <div className='mobile-qoute-btn111'>
                  <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M7.54017 8.6421C9.04077 12.7357 10.5943 14.3691 14.5464 16.1087L12.8336 18.5141C8.29539 16.3414 6.03498 12.6789 4.9704 9.82076L7.54017 8.6421Z" fill="white" />
                    <path d="M8.79918 2.98981C9.60159 2.98667 10.2117 3.65435 10.157 4.4104C10.0709 5.60016 10.1626 6.45855 10.4093 7.52256C10.5449 8.10703 10.2382 8.70064 9.75092 9.08298C9.0941 9.59833 8.59231 10.255 8.19349 10.8202C7.64071 11.6037 5.7384 11.5474 5.3177 10.6939C4.4531 8.93976 3.86716 5.70801 5.12973 3.52457C5.32804 3.18161 5.72736 3.00182 6.14113 3.0002L8.79918 2.98981Z" fill="white" />
                    <path d="M20.979 15.9681C21.0788 15.2162 20.4503 14.5639 19.649 14.5243C18.388 14.4619 17.4967 14.2727 16.4079 13.9134C15.8098 13.716 15.149 13.9322 14.6885 14.3431C14.0678 14.8969 13.3172 15.2884 12.675 15.5943C11.7849 16.0183 11.6153 17.8086 12.4619 18.3057C14.2019 19.3273 17.5286 20.2653 19.9756 19.3441C20.36 19.1994 20.597 18.8467 20.6484 18.4589L20.979 15.9681Z" fill="white" />
                  </svg></div>
                  CAll US Now
                </div>
              </a>
            </li>




          </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
