import React from "react";
import "./Section.css";
import homesvg1 from "../Images/Group 1000008556.png";
import homesvg2 from "../Images/Group 1000008555.png";
import homesvg3 from "../Images/Group 1000008557.png";
import homesvg4 from "../Images/Group 1000008560.png";
import homesvg5 from "../Images/Group 1000008559.png";
import homesvg6 from "../Images/Group 1000008558.png";
import bannerlisvg from "../Images/Group 1000008655.svg";
import footerimg from "../Images/image 2172.jpg";
import Faqs from "./Faqs";
import { useState } from "react";
import { questions } from "./Question";
import Fade from "react-reveal/Fade";
import formthankimg from "../Images/Group 1000008556.png";
import emailjs from '@emailjs/browser';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";


function Section() {
  const [faqs, setfaqs] = useState(questions);

  const [weight, setweight] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [name, setname] = useState();

  const [box, setbox] = useState();

  const [form1, setform1] = useState(false);
  const [form2, setform2] = useState(false);
  const [form3, setform3] = useState(false);

  const checkboxhandle = (e) => {
    const value = e.target.value;

    setbox(value);
  };

  const formSubmit = (e) => {
    e.preventDefault();


    emailjs
      .sendForm(
        "service_n3yey4b",
        "template_9dhg2rb",
        e.target,
        "aLY7nQLg_ggqz2UYb"
      ).then((e) => {
        setform1(true);
        setform2(true);
      });
  };

  const formSubmit2 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n3yey4b",
        "template_9dhg2rb",
        e.target,
        "aLY7nQLg_ggqz2UYb"

      ).then((e) => {
        setform1(true);
        setform2(false);
        setform3(true);
      });

  };

  return (
    <div>
      {/* // ############### Section No 5 ################# */}
      <div className="container-fluid home-choose-div1">
        <div className="container">
          <Fade bottom distance="1%">
            <h2 className="home-choose-text1">
              Why Choose<span> PK Cargo</span>
            </h2>
          </Fade>
          <Fade bottom distance="1%">
            <p className="home-choose-text2">
              We create an opportunity to reach business potential
            </p>
          </Fade>

          <div className="row home-choose-row-div1">
            {/* ######## card 1 ##########  */}
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
                  <Fade bottom distance="1%">
                    <img src={homesvg1} />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <h4>100% Satisfication</h4>
                </Fade>
                <Fade bottom distance="1%">
                  <p>PK Cargo aim to 100% Satisfaction to our clients.</p>
                </Fade>
              </div>
            </div>

            {/* ######## card 2##########  */}
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
                  <Fade bottom distance="1%">
                    <img src={homesvg2} />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <h4>In Time Delivery</h4>
                </Fade>
                <Fade bottom distance="1%">
                  <p>
                    We Always Deliver on time which time we said after booking.
                  </p>
                </Fade>
              </div>
            </div>

            {/* ######## card 3 ##########  */}
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
                  <Fade bottom distance="1%">
                    <img src={homesvg3} />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <h4>Safe Package</h4>
                </Fade>
                <Fade bottom distance="1%">
                  <p>Certified Full-Service Amazon Seller Management Agency</p>
                </Fade>
              </div>
            </div>

            {/* ######## card 4 ##########  */}
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
                  <Fade bottom distance="1%">
                    <img src={homesvg4} />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <h4>Transparent Pricing</h4>
                </Fade>
                <Fade bottom distance="1%">
                  <p>Offering clear, competitive rates with no hidden fees.</p>
                </Fade>
              </div>
            </div>

            {/* ######## card 5 ##########  */}
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
                  <Fade bottom distance="1%">
                    <img src={homesvg5} />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <h4>Quality Services</h4>
                </Fade>
                <Fade bottom distance="1%">
                  <p>
                    Our goal is to provide always quality services to our
                    customers
                  </p>
                </Fade>
              </div>
            </div>

            {/* ######## card 6 ##########  */}
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
                  <Fade bottom distance="1%">
                    <img src={homesvg6} />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <h4>Global Tracking</h4>
                </Fade>
                <Fade bottom distance="1%">
                  <p>Comprehensive Worldwide Coverage</p>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* // ####################### Section No 6 ################### */}
      <Fade bottom distance="0%">
        <div className="container-fluid home-form-div1" id="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 home-form-col-div1 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Fade bottom distance="1%">
                    <h2>
                      We take care about
                      <span> transportation</span> for your{" "}
                      <span>business.</span>
                    </h2>
                  </Fade>
                </div>
              </div>

              <div className="col-lg-6 mobile-form-div23">
                <Fade bottom distance="1%">
                  <div>



                    <div className="form-request-text-div1">
                      <h2>{`${form3 ? "SUBMITED" : "REQUEST A QUOTE"}`}</h2>
                    </div>

                    {/* // ####################### Form No 1 ################### */}

                    <div
                      className={`${form1
                        ? "form-main-div1 d-none"
                        : "form-main-div1 d-block"
                        }`}
                    >
                      <div>
                        <form onSubmit={formSubmit}>
                          <div class="form-row">
                            <div class="col-md-6 col-12">
                              <input
                                type="number"
                                name="phone"
                                class="form-control"
                                inputMode="numeric"
                                required
                                placeholder="Phone Number"
                                onChange={(e) => setphone(e.target.value)}
                              />
                            </div>
                            <div class="col-md-6 col-12 input2-field-div ">
                              <input
                                type="number"
                                inputMode="numeric"
                                name="weight"
                                class="form-control"
                                required
                                placeholder="Weight"
                                onChange={(e) => setweight(e.target.value)}
                              />
                            </div>



                            <div class="col-6">
                              <div
                                className={`${box === "sea cargo"
                                  ? "form-check1"
                                  : "form-not-check1"
                                  }`}
                              >
                                <input
                                  class="form-check-input1"
                                  type="radio"
                                  value="sea cargo"
                                  name="cargo"
                                  onChange={checkboxhandle}
                                />
                                <span></span>
                                <label class="form-check-label">
                                  Sea Cargo
                                </label>
                              </div>
                            </div>

                            <div class="col-6 ">
                              <div
                                className={`${box === "air cargo"
                                  ? "form-check1"
                                  : "form-not-check1"
                                  }`}
                              >
                                <input
                                  class="form-check-input1"
                                  type="radio"
                                  value="air cargo"
                                  name="cargo"
                                  onChange={checkboxhandle}
                                />
                                <span></span>
                                <label class="form-check-label">Air Cargo</label>
                              </div>
                            </div>

                            <div className="col-12">
                              <button
                                className="home-form-btn-div12"
                                type="submit"
                              >
                                <div>Get Quote</div>
                              </button>
                            </div>

                            <div className="col-12 ">
                              <div className="form-list-div1">
                                <div>
                                  <img src={bannerlisvg} alt="" />
                                  <p>Hassle Free </p>
                                </div>

                                <div>
                                  <img src={bannerlisvg} alt="" />
                                  <p>Door to Door Service</p>
                                </div>

                                <div>
                                  <img src={bannerlisvg} alt="" />
                                  <p>On Time</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* // ####################### Form No 2 ################### */}

                    <div
                      className={`${form2
                        ? "form-main-div1 d-block"
                        : "form-main-div1 d-none"
                        }`}
                    >
                      <div>
                        <form onSubmit={formSubmit2}>
                          <div class="form-row">

                            <input
                              type="hidden"
                              name="phone"
                              class="form-control"
                              defaultValue={phone}
                            />



                            <input
                              type="hidden"
                              name="weight"
                              class="form-control"
                              defaultValue={weight}
                            />



                            <input
                              type="hidden"
                              name="cargo"
                              class="form-control"
                              defaultValue={box}
                            />


                            <div class="col-lg-6 col-12">
                              <input
                                type="text"
                                name="name"
                                class="form-control"
                                required
                                placeholder="Your Name"
                                onChange={(e) => setname(e.target.value)}
                              />
                            </div>
                            <div class="col-lg-6 col-12 input2-field-div">
                              <input
                                type="email"
                                name="email"
                                class="form-control"
                                inputMode="email"
                                required
                                placeholder="Your Email"
                                onChange={(e) => setemail(e.target.value)}

                              />
                            </div>

                            <div className="col-12">
                              <p className="form-send-text1">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.47933 10.712 5.288C10.52 5.09667 10.2827 5.00067 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"
                                    fill="#F8B63D"
                                  />
                                </svg>{" "}
                                We will send you a quote within 12 to 24 hrs
                              </p>
                            </div>

                            <div className="col-12">
                              <button
                                className="home-form-btn-div12"
                                type="submit"
                              >
                                <div>Get Quote</div>
                              </button>
                            </div>

                            <div className="col-12 ">
                              <div className="form-list-div1">
                                <div>
                                  <img src={bannerlisvg} alt="" />
                                  <p>Hassle Free </p>
                                </div>

                                <div>
                                  <img src={bannerlisvg} alt="" />
                                  <p>Door to Door Service</p>
                                </div>

                                <div>
                                  <img src={bannerlisvg} alt="" />
                                  <p>On Time</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* // ####################### Form No 3 Thank You Message ################### */}

                    <div
                      className={`${form3
                        ? "form-main-div1 d-block"
                        : "form-main-div1 d-none"
                        }`}
                    >
                      <div className="form-thank-div31 d-flex align-items-center justify-content-center h-100">
                        <div className="text-center">
                          <img src={formthankimg} />
                          <h1 className="form-thank-text1">Thank You</h1>
                          <p className="form-thank-text2">
                            We will send you a quote within 12 to 24 hrs
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>

      {/* // ########### Section No 7 ############  */}

      <div className="container-fluid home-faqs-div1 ">
        <div className="container">
          <div>
            <Fade bottom distance="1%">
              <h1 className="home-faqs-text1">
                OUR <span>FAQ’s</span>
              </h1>
            </Fade>
            <Fade bottom distance="1%">
              <p className="home-faqs-para1">
                Cargo Frequently Asked Questions
              </p>
            </Fade>
            <div className="home-faq-div2">
              <div className="row">
                {/* <div className="col-lg-6 faqs-col-div1"> */}
                {faqs.map((faq) => {
                  return (
                    <Faqs
                      question={faq.question}
                      answer={faq.answer}
                      key={faq.id}
                    />
                  );
                })}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* // ################## Section No 8######################### */}
      <div className="container-fluid home-review-div1 d-md-block d-none">
        <div className="container">
          <div className="home-review-text-div1">
            <div>
              <Fade bottom distance="1%">
                <h2>
                  What people <span>say</span>
                </h2>
              </Fade>
            </div>

          </div>




          <div className="">

            <Swiper navigation={true} slidesPerView={2}
              spaceBetween={25} modules={[Navigation]} className="mySwiper">
              <SwiperSlide>

                <div className="col-12 home-review-col-div1">
                  <Fade bottom distance="1%">
                    <div>
                      <h2>
                        On time delivery <span>”</span>
                      </h2>
                      <p>
                        “Really great service delivered my cargo on time in Pakistan
                        safely, great customer service is kep. Recommend it for
                        everyone. Once again thanks!”
                      </p>
                      <h3>Shahzad Khan</h3>
                      <svg
                        width="152"
                        height="28"
                        viewBox="0 0 152 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9253 2.58809C12.1347 1.94376 13.0463 1.94376 13.2556 2.58809L15.2598 8.75622C15.3534 9.04438 15.6219 9.23947 15.9249 9.23947H22.4105C23.0879 9.23947 23.3696 10.1064 22.8215 10.5046L17.5746 14.3168C17.3295 14.4948 17.2269 14.8105 17.3205 15.0987L19.3247 21.2668C19.534 21.9111 18.7966 22.4469 18.2485 22.0487L13.0016 18.2366C12.7564 18.0585 12.4245 18.0585 12.1794 18.2366L6.93247 22.0487C6.38437 22.4469 5.64691 21.9111 5.85626 21.2668L7.86041 15.0987C7.95404 14.8105 7.85147 14.4948 7.60635 14.3168L2.35942 10.5046C1.81132 10.1064 2.09301 9.23947 2.7705 9.23947H9.25606C9.55904 9.23947 9.82757 9.04438 9.9212 8.75622L11.9253 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M37.1011 2.58809C37.3105 1.94376 38.222 1.94376 38.4314 2.58809L40.4355 8.75622C40.5292 9.04438 40.7977 9.23947 41.1007 9.23947H47.5862C48.2637 9.23947 48.5454 10.1064 47.9973 10.5046L42.7504 14.3168C42.5053 14.4948 42.4027 14.8105 42.4963 15.0987L44.5005 21.2668C44.7098 21.9111 43.9724 22.4469 43.4243 22.0487L38.1773 18.2366C37.9322 18.0585 37.6003 18.0585 37.3552 18.2366L32.1083 22.0487C31.5602 22.4469 30.8227 21.9111 31.032 21.2668L33.0362 15.0987C33.1298 14.8105 33.0273 14.4948 32.7821 14.3168L27.5352 10.5046C26.9871 10.1064 27.2688 9.23947 27.9463 9.23947H34.4318C34.7348 9.23947 35.0033 9.04438 35.097 8.75622L37.1011 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M62.2847 2.58809C62.4941 1.94376 63.4056 1.94376 63.615 2.58809L65.6191 8.75622C65.7128 9.04438 65.9813 9.23947 66.2843 9.23947H72.7698C73.4473 9.23947 73.729 10.1064 73.1809 10.5046L67.934 14.3168C67.6889 14.4948 67.5863 14.8105 67.6799 15.0987L69.6841 21.2668C69.8934 21.9111 69.156 22.4469 68.6079 22.0487L63.3609 18.2366C63.1158 18.0585 62.7839 18.0585 62.5388 18.2366L57.2918 22.0487C56.7437 22.4469 56.0063 21.9111 56.2156 21.2668L58.2198 15.0987C58.3134 14.8105 58.2108 14.4948 57.9657 14.3168L52.7188 10.5046C52.1707 10.1064 52.4524 9.23947 53.1299 9.23947H59.6154C59.9184 9.23947 60.1869 9.04438 60.2806 8.75622L62.2847 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M87.4605 2.58809C87.6699 1.94376 88.5814 1.94376 88.7908 2.58809L90.7949 8.75622C90.8885 9.04438 91.1571 9.23947 91.4601 9.23947H97.9456C98.6231 9.23947 98.9048 10.1064 98.3567 10.5046L93.1098 14.3168C92.8646 14.4948 92.7621 14.8105 92.8557 15.0987L94.8598 21.2668C95.0692 21.9111 94.3317 22.4469 93.7836 22.0487L88.5367 18.2366C88.2916 18.0585 87.9597 18.0585 87.7146 18.2366L82.4676 22.0487C81.9195 22.4469 81.1821 21.9111 81.3914 21.2668L83.3956 15.0987C83.4892 14.8105 83.3866 14.4948 83.1415 14.3168L77.8946 10.5046C77.3465 10.1064 77.6282 9.23947 78.3057 9.23947H84.7912C85.0942 9.23947 85.3627 9.04438 85.4564 8.75622L87.4605 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M112.168 2.58809C112.377 1.94376 113.288 1.94376 113.498 2.58809L115.502 8.75622C115.596 9.04438 115.864 9.23947 116.167 9.23947H122.653C123.33 9.23947 123.612 10.1064 123.064 10.5046L117.817 14.3168C117.572 14.4948 117.469 14.8105 117.563 15.0987L119.567 21.2668C119.776 21.9111 119.039 22.4469 118.491 22.0487L113.244 18.2366C112.999 18.0585 112.667 18.0585 112.422 18.2366L107.175 22.0487C106.627 22.4469 105.889 21.9111 106.098 21.2668L108.103 15.0987C108.196 14.8105 108.094 14.4948 107.849 14.3168L102.602 10.5046C102.054 10.1064 102.335 9.23947 103.013 9.23947H109.498C109.801 9.23947 110.07 9.04438 110.163 8.75622L112.168 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M115.536 8.68982L113.529 2.80214C113.35 1.9846 112.83 1.96512 112.83 2.80206V17.5816C112.83 17.8054 112.937 18.0158 113.118 18.1474L118.488 22.0487C119.036 22.4469 119.774 21.9111 119.564 21.2668L117.56 15.0986C117.467 14.8105 117.569 14.4948 117.814 14.3167L123.061 10.5046C123.609 10.1064 123.328 9.23945 122.65 9.23945H116.219C115.891 9.23945 115.606 9.01075 115.536 8.68982Z"
                          fill="#E6E6E6"
                        />
                        <path
                          d="M137.948 19.2598L138.267 17.4617H133.985L134.162 16.4444L139.142 10.7427H140.775L139.805 16.2906H140.905L140.692 17.4617H139.592L139.272 19.2598H137.948ZM135.747 16.2906H138.539L139.272 12.1149L135.747 16.2906ZM143.087 18.479C143.004 18.964 142.555 19.3426 142.081 19.3426C141.596 19.3426 141.289 18.964 141.372 18.479C141.454 17.994 141.904 17.6155 142.389 17.6155C142.862 17.6155 143.17 17.994 143.087 18.479ZM146.378 14.6345C145.715 14.2678 145.325 13.6527 145.491 12.6946C145.715 11.4407 146.839 10.3642 148.744 10.3642C150.648 10.3642 151.393 11.4525 151.169 12.6946C151.003 13.6172 150.352 14.2915 149.595 14.6345C150.412 14.9658 150.861 15.7347 150.672 16.8229C150.376 18.4554 148.921 19.4727 147.135 19.4727C145.361 19.4727 144.26 18.4554 144.556 16.8229C144.745 15.7228 145.467 14.9539 146.378 14.6345ZM148.543 11.488C147.62 11.488 146.922 11.9611 146.768 12.872C146.626 13.6527 147.17 14.2087 148.069 14.2087C148.968 14.2087 149.714 13.6409 149.856 12.8602C150.009 11.973 149.489 11.488 148.543 11.488ZM147.88 15.226C146.887 15.226 146.035 15.711 145.846 16.7756C145.68 17.7101 146.236 18.3489 147.336 18.3489C148.424 18.3489 149.193 17.6983 149.347 16.7756C149.536 15.7465 148.898 15.226 147.88 15.226Z"
                          fill="black"
                        />
                        <path
                          d="M133.736 20.4427H151.559V21.0341H133.736V20.4427Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </Fade>
                </div>
              </SwiperSlide>



              <SwiperSlide>


                {/* ######### card 2 ####### */}
                <div className="col-12 home-review-col-div1">
                  <Fade bottom distance="1%">
                    <div>
                      <h2>
                        as promised <span>”</span>
                      </h2>
                      <p>
                        “I sent clothes to Pakistan through this cargo company. They
                        delivered it on promised time even when it was shipped
                        through the sea. Really flattered.”
                      </p>
                      <h3>ZOHAIB</h3>
                      <svg
                        width="152"
                        height="28"
                        viewBox="0 0 152 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9253 2.58809C12.1347 1.94376 13.0463 1.94376 13.2556 2.58809L15.2598 8.75622C15.3534 9.04438 15.6219 9.23947 15.9249 9.23947H22.4105C23.0879 9.23947 23.3696 10.1064 22.8215 10.5046L17.5746 14.3168C17.3295 14.4948 17.2269 14.8105 17.3205 15.0987L19.3247 21.2668C19.534 21.9111 18.7966 22.4469 18.2485 22.0487L13.0016 18.2366C12.7564 18.0585 12.4245 18.0585 12.1794 18.2366L6.93247 22.0487C6.38437 22.4469 5.64691 21.9111 5.85626 21.2668L7.86041 15.0987C7.95404 14.8105 7.85147 14.4948 7.60635 14.3168L2.35942 10.5046C1.81132 10.1064 2.09301 9.23947 2.7705 9.23947H9.25606C9.55904 9.23947 9.82757 9.04438 9.9212 8.75622L11.9253 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M37.1011 2.58809C37.3105 1.94376 38.222 1.94376 38.4314 2.58809L40.4355 8.75622C40.5292 9.04438 40.7977 9.23947 41.1007 9.23947H47.5862C48.2637 9.23947 48.5454 10.1064 47.9973 10.5046L42.7504 14.3168C42.5053 14.4948 42.4027 14.8105 42.4963 15.0987L44.5005 21.2668C44.7098 21.9111 43.9724 22.4469 43.4243 22.0487L38.1773 18.2366C37.9322 18.0585 37.6003 18.0585 37.3552 18.2366L32.1083 22.0487C31.5602 22.4469 30.8227 21.9111 31.032 21.2668L33.0362 15.0987C33.1298 14.8105 33.0273 14.4948 32.7821 14.3168L27.5352 10.5046C26.9871 10.1064 27.2688 9.23947 27.9463 9.23947H34.4318C34.7348 9.23947 35.0033 9.04438 35.097 8.75622L37.1011 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M62.2847 2.58809C62.4941 1.94376 63.4056 1.94376 63.615 2.58809L65.6191 8.75622C65.7128 9.04438 65.9813 9.23947 66.2843 9.23947H72.7698C73.4473 9.23947 73.729 10.1064 73.1809 10.5046L67.934 14.3168C67.6889 14.4948 67.5863 14.8105 67.6799 15.0987L69.6841 21.2668C69.8934 21.9111 69.156 22.4469 68.6079 22.0487L63.3609 18.2366C63.1158 18.0585 62.7839 18.0585 62.5388 18.2366L57.2918 22.0487C56.7437 22.4469 56.0063 21.9111 56.2156 21.2668L58.2198 15.0987C58.3134 14.8105 58.2108 14.4948 57.9657 14.3168L52.7188 10.5046C52.1707 10.1064 52.4524 9.23947 53.1299 9.23947H59.6154C59.9184 9.23947 60.1869 9.04438 60.2806 8.75622L62.2847 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M87.4605 2.58809C87.6699 1.94376 88.5814 1.94376 88.7908 2.58809L90.7949 8.75622C90.8885 9.04438 91.1571 9.23947 91.4601 9.23947H97.9456C98.6231 9.23947 98.9048 10.1064 98.3567 10.5046L93.1098 14.3168C92.8646 14.4948 92.7621 14.8105 92.8557 15.0987L94.8598 21.2668C95.0692 21.9111 94.3317 22.4469 93.7836 22.0487L88.5367 18.2366C88.2916 18.0585 87.9597 18.0585 87.7146 18.2366L82.4676 22.0487C81.9195 22.4469 81.1821 21.9111 81.3914 21.2668L83.3956 15.0987C83.4892 14.8105 83.3866 14.4948 83.1415 14.3168L77.8946 10.5046C77.3465 10.1064 77.6282 9.23947 78.3057 9.23947H84.7912C85.0942 9.23947 85.3627 9.04438 85.4564 8.75622L87.4605 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M112.168 2.58809C112.377 1.94376 113.288 1.94376 113.498 2.58809L115.502 8.75622C115.596 9.04438 115.864 9.23947 116.167 9.23947H122.653C123.33 9.23947 123.612 10.1064 123.064 10.5046L117.817 14.3168C117.572 14.4948 117.469 14.8105 117.563 15.0987L119.567 21.2668C119.776 21.9111 119.039 22.4469 118.491 22.0487L113.244 18.2366C112.999 18.0585 112.667 18.0585 112.422 18.2366L107.175 22.0487C106.627 22.4469 105.889 21.9111 106.098 21.2668L108.103 15.0987C108.196 14.8105 108.094 14.4948 107.849 14.3168L102.602 10.5046C102.054 10.1064 102.335 9.23947 103.013 9.23947H109.498C109.801 9.23947 110.07 9.04438 110.163 8.75622L112.168 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M115.536 8.68982L113.529 2.80214C113.35 1.9846 112.83 1.96512 112.83 2.80206V17.5816C112.83 17.8054 112.937 18.0158 113.118 18.1474L118.488 22.0487C119.036 22.4469 119.774 21.9111 119.564 21.2668L117.56 15.0986C117.467 14.8105 117.569 14.4948 117.814 14.3167L123.061 10.5046C123.609 10.1064 123.328 9.23945 122.65 9.23945H116.219C115.891 9.23945 115.606 9.01075 115.536 8.68982Z"
                          fill="#E6E6E6"
                        />
                        <path
                          d="M137.948 19.2598L138.267 17.4617H133.985L134.162 16.4444L139.142 10.7427H140.775L139.805 16.2906H140.905L140.692 17.4617H139.592L139.272 19.2598H137.948ZM135.747 16.2906H138.539L139.272 12.1149L135.747 16.2906ZM143.087 18.479C143.004 18.964 142.555 19.3426 142.081 19.3426C141.596 19.3426 141.289 18.964 141.372 18.479C141.454 17.994 141.904 17.6155 142.389 17.6155C142.862 17.6155 143.17 17.994 143.087 18.479ZM146.378 14.6345C145.715 14.2678 145.325 13.6527 145.491 12.6946C145.715 11.4407 146.839 10.3642 148.744 10.3642C150.648 10.3642 151.393 11.4525 151.169 12.6946C151.003 13.6172 150.352 14.2915 149.595 14.6345C150.412 14.9658 150.861 15.7347 150.672 16.8229C150.376 18.4554 148.921 19.4727 147.135 19.4727C145.361 19.4727 144.26 18.4554 144.556 16.8229C144.745 15.7228 145.467 14.9539 146.378 14.6345ZM148.543 11.488C147.62 11.488 146.922 11.9611 146.768 12.872C146.626 13.6527 147.17 14.2087 148.069 14.2087C148.968 14.2087 149.714 13.6409 149.856 12.8602C150.009 11.973 149.489 11.488 148.543 11.488ZM147.88 15.226C146.887 15.226 146.035 15.711 145.846 16.7756C145.68 17.7101 146.236 18.3489 147.336 18.3489C148.424 18.3489 149.193 17.6983 149.347 16.7756C149.536 15.7465 148.898 15.226 147.88 15.226Z"
                          fill="black"
                        />
                        <path
                          d="M133.736 20.4427H151.559V21.0341H133.736V20.4427Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </Fade>


                </div>
              </SwiperSlide>














              <SwiperSlide>

                <div className="col-12 home-review-col-div1">
                  <Fade bottom distance="1%">
                    <div>
                      <h2>
                        Exceptional Service <span>”</span>
                      </h2>
                      <p>
                        “I've been using this cargo service for my business shipments from the UK to Pakistan, and I'm thoroughly impressed. Highly recommended!”
                      </p>
                      <h3>Muhammad Taha</h3>
                      <svg
                        width="152"
                        height="28"
                        viewBox="0 0 152 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9253 2.58809C12.1347 1.94376 13.0463 1.94376 13.2556 2.58809L15.2598 8.75622C15.3534 9.04438 15.6219 9.23947 15.9249 9.23947H22.4105C23.0879 9.23947 23.3696 10.1064 22.8215 10.5046L17.5746 14.3168C17.3295 14.4948 17.2269 14.8105 17.3205 15.0987L19.3247 21.2668C19.534 21.9111 18.7966 22.4469 18.2485 22.0487L13.0016 18.2366C12.7564 18.0585 12.4245 18.0585 12.1794 18.2366L6.93247 22.0487C6.38437 22.4469 5.64691 21.9111 5.85626 21.2668L7.86041 15.0987C7.95404 14.8105 7.85147 14.4948 7.60635 14.3168L2.35942 10.5046C1.81132 10.1064 2.09301 9.23947 2.7705 9.23947H9.25606C9.55904 9.23947 9.82757 9.04438 9.9212 8.75622L11.9253 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M37.1011 2.58809C37.3105 1.94376 38.222 1.94376 38.4314 2.58809L40.4355 8.75622C40.5292 9.04438 40.7977 9.23947 41.1007 9.23947H47.5862C48.2637 9.23947 48.5454 10.1064 47.9973 10.5046L42.7504 14.3168C42.5053 14.4948 42.4027 14.8105 42.4963 15.0987L44.5005 21.2668C44.7098 21.9111 43.9724 22.4469 43.4243 22.0487L38.1773 18.2366C37.9322 18.0585 37.6003 18.0585 37.3552 18.2366L32.1083 22.0487C31.5602 22.4469 30.8227 21.9111 31.032 21.2668L33.0362 15.0987C33.1298 14.8105 33.0273 14.4948 32.7821 14.3168L27.5352 10.5046C26.9871 10.1064 27.2688 9.23947 27.9463 9.23947H34.4318C34.7348 9.23947 35.0033 9.04438 35.097 8.75622L37.1011 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M62.2847 2.58809C62.4941 1.94376 63.4056 1.94376 63.615 2.58809L65.6191 8.75622C65.7128 9.04438 65.9813 9.23947 66.2843 9.23947H72.7698C73.4473 9.23947 73.729 10.1064 73.1809 10.5046L67.934 14.3168C67.6889 14.4948 67.5863 14.8105 67.6799 15.0987L69.6841 21.2668C69.8934 21.9111 69.156 22.4469 68.6079 22.0487L63.3609 18.2366C63.1158 18.0585 62.7839 18.0585 62.5388 18.2366L57.2918 22.0487C56.7437 22.4469 56.0063 21.9111 56.2156 21.2668L58.2198 15.0987C58.3134 14.8105 58.2108 14.4948 57.9657 14.3168L52.7188 10.5046C52.1707 10.1064 52.4524 9.23947 53.1299 9.23947H59.6154C59.9184 9.23947 60.1869 9.04438 60.2806 8.75622L62.2847 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M87.4605 2.58809C87.6699 1.94376 88.5814 1.94376 88.7908 2.58809L90.7949 8.75622C90.8885 9.04438 91.1571 9.23947 91.4601 9.23947H97.9456C98.6231 9.23947 98.9048 10.1064 98.3567 10.5046L93.1098 14.3168C92.8646 14.4948 92.7621 14.8105 92.8557 15.0987L94.8598 21.2668C95.0692 21.9111 94.3317 22.4469 93.7836 22.0487L88.5367 18.2366C88.2916 18.0585 87.9597 18.0585 87.7146 18.2366L82.4676 22.0487C81.9195 22.4469 81.1821 21.9111 81.3914 21.2668L83.3956 15.0987C83.4892 14.8105 83.3866 14.4948 83.1415 14.3168L77.8946 10.5046C77.3465 10.1064 77.6282 9.23947 78.3057 9.23947H84.7912C85.0942 9.23947 85.3627 9.04438 85.4564 8.75622L87.4605 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M112.168 2.58809C112.377 1.94376 113.288 1.94376 113.498 2.58809L115.502 8.75622C115.596 9.04438 115.864 9.23947 116.167 9.23947H122.653C123.33 9.23947 123.612 10.1064 123.064 10.5046L117.817 14.3168C117.572 14.4948 117.469 14.8105 117.563 15.0987L119.567 21.2668C119.776 21.9111 119.039 22.4469 118.491 22.0487L113.244 18.2366C112.999 18.0585 112.667 18.0585 112.422 18.2366L107.175 22.0487C106.627 22.4469 105.889 21.9111 106.098 21.2668L108.103 15.0987C108.196 14.8105 108.094 14.4948 107.849 14.3168L102.602 10.5046C102.054 10.1064 102.335 9.23947 103.013 9.23947H109.498C109.801 9.23947 110.07 9.04438 110.163 8.75622L112.168 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M115.536 8.68982L113.529 2.80214C113.35 1.9846 112.83 1.96512 112.83 2.80206V17.5816C112.83 17.8054 112.937 18.0158 113.118 18.1474L118.488 22.0487C119.036 22.4469 119.774 21.9111 119.564 21.2668L117.56 15.0986C117.467 14.8105 117.569 14.4948 117.814 14.3167L123.061 10.5046C123.609 10.1064 123.328 9.23945 122.65 9.23945H116.219C115.891 9.23945 115.606 9.01075 115.536 8.68982Z"
                          fill="#E6E6E6"
                        />
                        <path
                          d="M137.948 19.2598L138.267 17.4617H133.985L134.162 16.4444L139.142 10.7427H140.775L139.805 16.2906H140.905L140.692 17.4617H139.592L139.272 19.2598H137.948ZM135.747 16.2906H138.539L139.272 12.1149L135.747 16.2906ZM143.087 18.479C143.004 18.964 142.555 19.3426 142.081 19.3426C141.596 19.3426 141.289 18.964 141.372 18.479C141.454 17.994 141.904 17.6155 142.389 17.6155C142.862 17.6155 143.17 17.994 143.087 18.479ZM146.378 14.6345C145.715 14.2678 145.325 13.6527 145.491 12.6946C145.715 11.4407 146.839 10.3642 148.744 10.3642C150.648 10.3642 151.393 11.4525 151.169 12.6946C151.003 13.6172 150.352 14.2915 149.595 14.6345C150.412 14.9658 150.861 15.7347 150.672 16.8229C150.376 18.4554 148.921 19.4727 147.135 19.4727C145.361 19.4727 144.26 18.4554 144.556 16.8229C144.745 15.7228 145.467 14.9539 146.378 14.6345ZM148.543 11.488C147.62 11.488 146.922 11.9611 146.768 12.872C146.626 13.6527 147.17 14.2087 148.069 14.2087C148.968 14.2087 149.714 13.6409 149.856 12.8602C150.009 11.973 149.489 11.488 148.543 11.488ZM147.88 15.226C146.887 15.226 146.035 15.711 145.846 16.7756C145.68 17.7101 146.236 18.3489 147.336 18.3489C148.424 18.3489 149.193 17.6983 149.347 16.7756C149.536 15.7465 148.898 15.226 147.88 15.226Z"
                          fill="black"
                        />
                        <path
                          d="M133.736 20.4427H151.559V21.0341H133.736V20.4427Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </Fade>
                </div>

              </SwiperSlide>

              <SwiperSlide>


                {/* ######### card 2 ####### */}
                <div className="col-12 home-review-col-div1">
                  <Fade bottom distance="1%">
                    <div>
                      <h2>
                        Reliable Partner <span>”</span>
                      </h2>
                      <p>
                        “We've been using this cargo service for our commercial shipments, and they've consistently provided efficient and cost-effective solutions.”
                      </p>
                      <h3>Nawaz</h3>
                      <svg
                        width="152"
                        height="28"
                        viewBox="0 0 152 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9253 2.58809C12.1347 1.94376 13.0463 1.94376 13.2556 2.58809L15.2598 8.75622C15.3534 9.04438 15.6219 9.23947 15.9249 9.23947H22.4105C23.0879 9.23947 23.3696 10.1064 22.8215 10.5046L17.5746 14.3168C17.3295 14.4948 17.2269 14.8105 17.3205 15.0987L19.3247 21.2668C19.534 21.9111 18.7966 22.4469 18.2485 22.0487L13.0016 18.2366C12.7564 18.0585 12.4245 18.0585 12.1794 18.2366L6.93247 22.0487C6.38437 22.4469 5.64691 21.9111 5.85626 21.2668L7.86041 15.0987C7.95404 14.8105 7.85147 14.4948 7.60635 14.3168L2.35942 10.5046C1.81132 10.1064 2.09301 9.23947 2.7705 9.23947H9.25606C9.55904 9.23947 9.82757 9.04438 9.9212 8.75622L11.9253 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M37.1011 2.58809C37.3105 1.94376 38.222 1.94376 38.4314 2.58809L40.4355 8.75622C40.5292 9.04438 40.7977 9.23947 41.1007 9.23947H47.5862C48.2637 9.23947 48.5454 10.1064 47.9973 10.5046L42.7504 14.3168C42.5053 14.4948 42.4027 14.8105 42.4963 15.0987L44.5005 21.2668C44.7098 21.9111 43.9724 22.4469 43.4243 22.0487L38.1773 18.2366C37.9322 18.0585 37.6003 18.0585 37.3552 18.2366L32.1083 22.0487C31.5602 22.4469 30.8227 21.9111 31.032 21.2668L33.0362 15.0987C33.1298 14.8105 33.0273 14.4948 32.7821 14.3168L27.5352 10.5046C26.9871 10.1064 27.2688 9.23947 27.9463 9.23947H34.4318C34.7348 9.23947 35.0033 9.04438 35.097 8.75622L37.1011 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M62.2847 2.58809C62.4941 1.94376 63.4056 1.94376 63.615 2.58809L65.6191 8.75622C65.7128 9.04438 65.9813 9.23947 66.2843 9.23947H72.7698C73.4473 9.23947 73.729 10.1064 73.1809 10.5046L67.934 14.3168C67.6889 14.4948 67.5863 14.8105 67.6799 15.0987L69.6841 21.2668C69.8934 21.9111 69.156 22.4469 68.6079 22.0487L63.3609 18.2366C63.1158 18.0585 62.7839 18.0585 62.5388 18.2366L57.2918 22.0487C56.7437 22.4469 56.0063 21.9111 56.2156 21.2668L58.2198 15.0987C58.3134 14.8105 58.2108 14.4948 57.9657 14.3168L52.7188 10.5046C52.1707 10.1064 52.4524 9.23947 53.1299 9.23947H59.6154C59.9184 9.23947 60.1869 9.04438 60.2806 8.75622L62.2847 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M87.4605 2.58809C87.6699 1.94376 88.5814 1.94376 88.7908 2.58809L90.7949 8.75622C90.8885 9.04438 91.1571 9.23947 91.4601 9.23947H97.9456C98.6231 9.23947 98.9048 10.1064 98.3567 10.5046L93.1098 14.3168C92.8646 14.4948 92.7621 14.8105 92.8557 15.0987L94.8598 21.2668C95.0692 21.9111 94.3317 22.4469 93.7836 22.0487L88.5367 18.2366C88.2916 18.0585 87.9597 18.0585 87.7146 18.2366L82.4676 22.0487C81.9195 22.4469 81.1821 21.9111 81.3914 21.2668L83.3956 15.0987C83.4892 14.8105 83.3866 14.4948 83.1415 14.3168L77.8946 10.5046C77.3465 10.1064 77.6282 9.23947 78.3057 9.23947H84.7912C85.0942 9.23947 85.3627 9.04438 85.4564 8.75622L87.4605 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M112.168 2.58809C112.377 1.94376 113.288 1.94376 113.498 2.58809L115.502 8.75622C115.596 9.04438 115.864 9.23947 116.167 9.23947H122.653C123.33 9.23947 123.612 10.1064 123.064 10.5046L117.817 14.3168C117.572 14.4948 117.469 14.8105 117.563 15.0987L119.567 21.2668C119.776 21.9111 119.039 22.4469 118.491 22.0487L113.244 18.2366C112.999 18.0585 112.667 18.0585 112.422 18.2366L107.175 22.0487C106.627 22.4469 105.889 21.9111 106.098 21.2668L108.103 15.0987C108.196 14.8105 108.094 14.4948 107.849 14.3168L102.602 10.5046C102.054 10.1064 102.335 9.23947 103.013 9.23947H109.498C109.801 9.23947 110.07 9.04438 110.163 8.75622L112.168 2.58809Z"
                          fill="#F7B53C"
                        />
                        <path
                          d="M115.536 8.68982L113.529 2.80214C113.35 1.9846 112.83 1.96512 112.83 2.80206V17.5816C112.83 17.8054 112.937 18.0158 113.118 18.1474L118.488 22.0487C119.036 22.4469 119.774 21.9111 119.564 21.2668L117.56 15.0986C117.467 14.8105 117.569 14.4948 117.814 14.3167L123.061 10.5046C123.609 10.1064 123.328 9.23945 122.65 9.23945H116.219C115.891 9.23945 115.606 9.01075 115.536 8.68982Z"
                          fill="#E6E6E6"
                        />
                        <path
                          d="M137.948 19.2598L138.267 17.4617H133.985L134.162 16.4444L139.142 10.7427H140.775L139.805 16.2906H140.905L140.692 17.4617H139.592L139.272 19.2598H137.948ZM135.747 16.2906H138.539L139.272 12.1149L135.747 16.2906ZM143.087 18.479C143.004 18.964 142.555 19.3426 142.081 19.3426C141.596 19.3426 141.289 18.964 141.372 18.479C141.454 17.994 141.904 17.6155 142.389 17.6155C142.862 17.6155 143.17 17.994 143.087 18.479ZM146.378 14.6345C145.715 14.2678 145.325 13.6527 145.491 12.6946C145.715 11.4407 146.839 10.3642 148.744 10.3642C150.648 10.3642 151.393 11.4525 151.169 12.6946C151.003 13.6172 150.352 14.2915 149.595 14.6345C150.412 14.9658 150.861 15.7347 150.672 16.8229C150.376 18.4554 148.921 19.4727 147.135 19.4727C145.361 19.4727 144.26 18.4554 144.556 16.8229C144.745 15.7228 145.467 14.9539 146.378 14.6345ZM148.543 11.488C147.62 11.488 146.922 11.9611 146.768 12.872C146.626 13.6527 147.17 14.2087 148.069 14.2087C148.968 14.2087 149.714 13.6409 149.856 12.8602C150.009 11.973 149.489 11.488 148.543 11.488ZM147.88 15.226C146.887 15.226 146.035 15.711 145.846 16.7756C145.68 17.7101 146.236 18.3489 147.336 18.3489C148.424 18.3489 149.193 17.6983 149.347 16.7756C149.536 15.7465 148.898 15.226 147.88 15.226Z"
                          fill="black"
                        />
                        <path
                          d="M133.736 20.4427H151.559V21.0341H133.736V20.4427Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </Fade>


                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
