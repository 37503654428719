export const questions = [
    {
        id: 1,
        question: "What types of cargo do you transport?",
      answer: `We transport a wide variety of cargo, including commercial goods, personal effects, electronics, vehicles, machinery, and more. Our services cater to diverse shipping needs. `,
        // answer2: ` voluptas facere dolores doloribus veritatis, tenetur, doloremque aperiam at perferendis nobis nostrum maxime perspiciatis.`,

    },

    {
        id: 2,
        question: "What are your shipping routes?",
      answer: `We specialize in cargo transportation from the UK to Pakistan, offering efficient and reliable shipping routes between these two countries. `,
        // answer2: ` voluptas facere dolores doloribus veritatis, tenetur, doloremque aperiam at perferendis nobis nostrum maxime perspiciatis.`,

    },

    {
        id: 3,
      question: "How do I book your services?",
      answer: `Booking our services is simple. You can reach out to our team via phone, email, or through our website's booking form. Our representatives will guide you through the process and assist with any inquiries you may have. `,
        // answer2: ` voluptas facere dolores doloribus veritatis, tenetur, doloremque aperiam at perferendis nobis nostrum maxime perspiciatis.`,

    },
    {
        id: 4,
      question: "What are your shipping rates?",
      answer: `Our shipping rates depend on various factors such as the size and weight of the cargo, the shipping distance, and any additional services required. Contact us for a personalized quote tailored to your specific shipping needs. `,
        // answer2: ` voluptas facere dolores doloribus veritatis, tenetur, doloremque aperiam at perferendis nobis nostrum maxime perspiciatis.`,

    },
    {
        id: 5,
      question: "How do you ensure the safety of my cargo during transit?",
      answer: `We prioritize the safety and security of your cargo throughout the transportation process. Our experienced team handles each shipment with care, and we employ industry-standard packaging and handling procedures to minimize any risks during transit. `,
        // answer2: ` voluptas facere dolores doloribus veritatis, tenetur, doloremque aperiam at perferendis nobis nostrum maxime perspiciatis.`,

    },
    {
        id: 6,
      question: "What if I have specific requirements or special cargo handling needs?",
      answer: `We understand that each shipment may have unique requirements, and we are committed to accommodating your specific needs. Whether you have oversized cargo, perishable items, or special handling instructions, our team will work closely with you to ensure your requirements are met with precision and care. `,
    },


]