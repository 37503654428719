import React, {useState} from 'react'
import './Contact.css'
import bannerlisvg from "../Images/Group 1000008655.svg";
import bannerstarsimg from "../Images/Group 1000008511.svg";
import contacttruck from '../Images/Trailer-Truck-Mockup-Free-PSD---View-1 2.png'

import cardimg1 from "../Images/Group 1000008659.svg";
import cardimg2 from "../Images/Group 1000008660.svg";
import cardimg3 from "../Images/Group 1000008517.svg";
import cardimg4 from "../Images/Group 1000008658.svg";
import homesvg1 from "../Images/Group 1000008556.png";
import homesvg2 from "../Images/Group 1000008555.png";
import homesvg3 from "../Images/Group 1000008557.png";
import homesvg4 from "../Images/Group 1000008560.png";
import homesvg5 from "../Images/Group 1000008559.png";
import homesvg6 from "../Images/Group 1000008558.png";
import formthankimg from '../Images/Group 1000008556.png'
import emailjs from '@emailjs/browser';
import truckimg from '../Images/Trailer-Truck-Mockup-Free-PSD---View-1 2 (1).png'
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";



function Contact() {


  const [weight, setweight] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [name, setname] = useState();

  const [box, setbox] = useState();

  const [form1, setform1] = useState(false);
  const [form2, setform2] = useState(false);
  const [form3, setform3] = useState(false);

  const checkboxhandle = (e) => {
    const value = e.target.value;

    setbox(value);
  };

  const formSubmit = (e) => {
    e.preventDefault();


    emailjs
      .sendForm(
        "service_n3yey4b",
        "template_9dhg2rb",
        e.target,
        "aLY7nQLg_ggqz2UYb"
      ).then((e) => {
        setform1(true);
        setform2(true);
      });
  };

  const formSubmit2 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n3yey4b",
        "template_9dhg2rb",
        e.target,
        "aLY7nQLg_ggqz2UYb"

      ).then((e) => {
        setform1(true);
        setform2(false);
        setform3(true);
      });

  };
  return (
    <div className='contact-mains-div1'>
      <Helmet>
        <title>PK Cargo </title>
        <meta name="description" content="Get in Touch: Reach Out to Our Expert Team for Hassle-Free Cargo Solutions. Contact Us Today for Inquiries, Bookings, or Assistance. Your Trusted Connection from the UK to Pakistan." />
      </Helmet>
      <div className='conatiner-fluid contact-div1'>
        <div className='container position-relative'>
          <div className='row'>
            <div className='col-lg-6 for-mobile-about-banner-col'>

              <div>
      {/* <Fade bottom distance="1%"> */}
                <div className="home-banner-stars-div1">
                  <img src={bannerstarsimg} />
                  <p>Loved by 100,000+ Happy cusmtomers</p>
                </div>
                {/* </Fade> */}
                <div className='text-center'>
                  {/* <Fade bottom distance="1%"> */}
                  <h1 className="home-banner-text1">
                    we will respond you in<br></br>
                    <span> 12 to 24 hours</span>
                  </h1>
                  {/* </Fade> */}
                  {/* <Fade bottom distance="1%"> */}
                  <img src={truckimg} className='img-fluid contact-banners-truck-img d-md-none d-block mx-auto'  alt="" />
{/* </Fade> */}
      {/* <Fade bottom distance="1%"> */}

                  <ul className="home-banner-ul-div1 d-md-block d-none">
                    <li>
                      <img src={bannerlisvg} alt="" />
                      <p>No Custom, No Duty</p>
                    </li>
                    <li>
                      <img src={bannerlisvg} alt="" />
                      <p>No Handling Charges</p>
                    </li>
                    <li>
                      <img src={bannerlisvg} alt="" />
                      <p>Door to Door Service</p>
                    </li>
                  </ul>
{/* </Fade> */}

                  <div className='d-md-block d-none'>
      {/* <Fade bottom distance="1%"> */}
                    <a href="">
                      <div className="banner-quote-btn1">
                        Get a Quote{" "}
                        <svg
                          width="15"
                          height="13"
                          viewBox="0 0 15 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.80958 13L7.77052 11.9744L12.0482 7.69673H0.969379V6.21236H12.0482L7.77052 1.94815L8.80958 0.909091L14.855 6.95455L8.80958 13Z"
                            fill="#01070C"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* </Fade> */}
                  </div>
                </div>
              </div>
            </div>



            <div className='col-lg-6 contact-us-form-main-div1'>
              <div className='contact-truck-img-div1 d-md-block d-none '>
                <div>
                  <img src={contacttruck} alt="" className='contact-truck-animation' />
                </div>
              </div>
              <div className='contact-form-div55'>
                



                <div className="form-request-text-div1">
                  <h2>{`${form3 ? "SUBMITED" : "REQUEST A QUOTE"}`}</h2>
                </div>

                {/* // ####################### Form No 1 ################### */}

                <div
                  className={`${form1
                    ? "form-main-div1 d-none"
                    : "form-main-div1 d-block"
                    }`}
                >
                  <div>
                    <form onSubmit={formSubmit}>
                      <div class="form-row">
                        <div class="col-md-6 col-12">
                          <input
                            type="number"
                            name="phone"
                            class="form-control"
                            inputMode="numeric"
                            required
                            placeholder="Phone Number"
                            onChange={(e) => setphone(e.target.value)}
                          />
                        </div>
                        <div class="col-md-6 col-12 input2-field-div ">
                          <input
                            type="number"
                            inputMode="numeric"
                            name="weight"
                            class="form-control"
                            required
                            placeholder="Weight"
                            onChange={(e) => setweight(e.target.value)}
                          />
                        </div>



                        <div class="col-6">
                          <div
                            className={`${box === "sea cargo"
                              ? "form-check1"
                              : "form-not-check1"
                              }`}
                          >
                            <input
                              class="form-check-input1"
                              type="radio"
                              value="sea cargo"
                              name="cargo"
                              onChange={checkboxhandle}
                            />
                            <span></span>
                            <label class="form-check-label">
                              Sea Cargo
                            </label>
                          </div>
                        </div>

                        <div class="col-6 ">
                          <div
                            className={`${box === "air cargo"
                              ? "form-check1"
                              : "form-not-check1"
                              }`}
                          >
                            <input
                              class="form-check-input1"
                              type="radio"
                              value="air cargo"
                              name="cargo"
                              onChange={checkboxhandle}
                            />
                            <span></span>
                            <label class="form-check-label">Air Cargo</label>
                          </div>
                        </div>

                        <div className="col-12">
                          <button
                            className="home-form-btn-div12"
                            type="submit"
                          >
                            <div>Get Quote</div>
                          </button>
                        </div>

                        <div className="col-12 ">
                          <div className="form-list-div1">
                            <div>
                              <img src={bannerlisvg} alt="" />
                              <p>Hassle Free </p>
                            </div>

                            <div>
                              <img src={bannerlisvg} alt="" />
                              <p>Door to Door Service</p>
                            </div>

                            <div>
                              <img src={bannerlisvg} alt="" />
                              <p>On Time</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {/* // ####################### Form No 2 ################### */}

                <div
                  className={`${form2
                    ? "form-main-div1 d-block"
                    : "form-main-div1 d-none"
                    }`}
                >
                  <div>
                    <form onSubmit={formSubmit2}>
                      <div class="form-row">

                        <input
                          type="hidden"
                          name="phone"
                          class="form-control"
                          defaultValue={phone}
                        />



                        <input
                          type="hidden"
                          name="weight"
                          class="form-control"
                          defaultValue={weight}
                        />



                        <input
                          type="hidden"
                          name="cargo"
                          class="form-control"
                          defaultValue={box}
                        />


                        <div class="col-lg-6 col-12">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            required
                            placeholder="Your Name"
                            onChange={(e) => setname(e.target.value)}
                          />
                        </div>
                        <div class="col-lg-6 col-12 input2-field-div">
                          <input
                            type="email"
                            name="email"
                            class="form-control"
                            inputMode="email"
                            required
                            placeholder="Your Email"
                            onChange={(e) => setemail(e.target.value)}

                          />
                        </div>

                        <div className="col-12">
                          <p className="form-send-text1">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.47933 10.712 5.288C10.52 5.09667 10.2827 5.00067 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"
                                fill="#F8B63D"
                              />
                            </svg>{" "}
                            We will send you a quote within 12 to 24 hrs
                          </p>
                        </div>

                        <div className="col-12">
                          <button
                            className="home-form-btn-div12"
                            type="submit"
                          >
                            <div>Get Quote</div>
                          </button>
                        </div>

                        <div className="col-12 ">
                          <div className="form-list-div1">
                            <div>
                              <img src={bannerlisvg} alt="" />
                              <p>Hassle Free </p>
                            </div>

                            <div>
                              <img src={bannerlisvg} alt="" />
                              <p>Door to Door Service</p>
                            </div>

                            <div>
                              <img src={bannerlisvg} alt="" />
                              <p>On Time</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {/* // ####################### Form No 3 Thank You Message ################### */}

                <div
                  className={`${form3
                    ? "form-main-div1 d-block"
                    : "form-main-div1 d-none"
                    }`}
                >
                  <div className="form-thank-div31 d-flex align-items-center justify-content-center h-100">
                    <div className="text-center">
                      <img src={formthankimg} />
                      <h1 className="form-thank-text1">Thank You</h1>
                      <p className="form-thank-text2">
                        We will send you a quote within 12 to 24 hrs
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>









<div className='for-mobile-contact-sec3-div d-md-none d-block'>


      {/* // ########### Section No 3 ############ */}
      <div className="container-fluid home-sec3-card-div11 ">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3 col-6">
      <Fade bottom distance="1%">

              <div className="home-sec3-card-div22">
                <div>
                  <img src={cardimg1} alt="" />
                </div>
                <h2>50+</h2>
                <p>Countries Served</p>
              </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
      <Fade bottom distance="1%">

              <div className="home-sec3-card-div22">
                <div>
                  <img src={cardimg2} alt="" />
                </div>
                <h2>100K</h2>
                <p>Delivery Made</p>
              </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
      <Fade bottom distance="1%">

              <div className="home-sec3-card-div22">
                <div>
                  <img src={cardimg3} alt="" />
                </div>
                <h2>99%</h2>
                <p>Satisfaction Rate</p>
              </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
      <Fade bottom distance="1%">

              <div className="home-sec3-card-div22">
                <div>
                  <img src={cardimg4} alt="" />
                </div>
                <h2>24/7</h2>
                <p>Support Hours</p>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>


</div>






      {/* // ########### Section No 5 ############ */}
      <div className="container-fluid home-choose-div1">
      
        <div className="container">
          <Fade bottom distance="1%">
          <h2 className="home-choose-text1">
            Why Choose<span> PK Cargo</span>
          </h2>
          </Fade>
          <Fade bottom distance="1%">
          <p className="home-choose-text2">
            We create an opportunity to reach business potential
          </p>
</Fade>
          <div className="row home-choose-row-div1">
            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
      <Fade bottom distance="1%">
                  <img src={homesvg1} />
                  </Fade>
                </div>
      <Fade bottom distance="1%">
                <h4>100% Satisfication</h4>
                </Fade>
      <Fade bottom distance="1%">
                <p>PK Cargo aim to 100% Satisfaction to our clients.</p>
                </Fade>
              </div>
            </div>

            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
      <Fade bottom distance="1%">
                  <img src={homesvg2} />
                  </Fade>
                </div>
      <Fade bottom distance="1%">
                <h4>In Time Delivery</h4>
 
 </Fade>
      <Fade bottom distance="1%">
               <p>We Always Deliver on time which time we said after booking.</p>
               </Fade>
              </div>
            </div>

            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
      <Fade bottom distance="1%">
                  <img src={homesvg3} />
                  </Fade>
                </div>
      <Fade bottom distance="1%">
                <h4>Safe Package</h4>
     
     </Fade>
      <Fade bottom distance="1%">
               <p>Certified Full-Service Amazon Seller
                  Management Agency</p>
                  </Fade>
              </div>
            </div>

            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
      <Fade bottom distance="1%">
                  <img src={homesvg4} />
                  </Fade>
                </div>
      <Fade bottom distance="1%">
                <h4>Transparent Pricing</h4>
                </Fade>
      <Fade bottom distance="1%">
                <p>Offering clear, competitive rates with no hidden fees.</p>
                </Fade>
              </div>
            </div>

            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
      <Fade bottom distance="1%">
                  <img src={homesvg5} />
                  </Fade>
                </div>
      <Fade bottom distance="1%">
                <h4>Quality Services</h4>
 
 </Fade>
      <Fade bottom distance="1%">
               <p>Our goal is to provide always quality services to our customers</p>
               </Fade>
              </div>
            </div>

            <div className="col-lg-4 col-6 home-choose-col-div1">
              <div>
                <div className="home-svg-div12">
      <Fade bottom distance="1%">
                  <img src={homesvg6} />
                  </Fade>
                </div>
      <Fade bottom distance="1%">
                <h4>Global Tracking</h4>
  
  </Fade>
      <Fade bottom distance="1%">
              <p>Comprehensive Worldwide Coverage</p>
              </Fade>
              </div>
            </div>
          </div>
        </div>
        </div>

    </div>
  )
}

export default Contact
